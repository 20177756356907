import React from 'react';
import {
  InlineTextField,
  InlineWysiwyg,
} from 'react-tinacms-inline';
import ContactsSection from 'gatsby-theme-dorian/src/sections/ContactsSection';


export function Contacts({
  data, html, index, ...props
}) {
  const {
    api, latitude, longitude, marker, zoom, height,
  } = data;
  const latFloat = parseFloat(latitude, 10);
  const lngFloat = parseFloat(longitude, 10);
  const Title = <InlineTextField name={`rawJson.blocks[${index}].title`} />;
  return (
    <ContactsSection
      id={data.title.toLowerCase()}
      title={Title}
      mapData={{
        lat: latFloat,
        lng: lngFloat,
        zoom,
        api,
        marker,
        height,
      }}
      {...props}
    >
      <InlineWysiwyg name={`rawJson.blocks[${index}].content`} format="markdown">
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </InlineWysiwyg>
    </ContactsSection>
  );
}

export const ContactsBlock = {
  label: 'Contacts',
  name: 'contacts',
  key: 'test',
  defaultItem: {
    title: 'Contacts',
    content: 'We are located on Awesome St',
    marker: 'Our office',
    latitude: '59.955413',
    longitude: '30.337844',
    zoom: '11',
    height: '350px',
    api: '',
  },
  fields: [
    { name: 'title', label: 'Title', component: 'text' },
    { name: 'content', label: 'Content', component: 'markdown' },
    { name: 'marker', label: 'Marker title', component: 'text' },
    { name: 'latitude', label: 'Latitude', component: 'text' },
    { name: 'longitude', label: 'Longitude', component: 'text' },
    { name: 'zoom', label: 'Zoom', component: 'text' },
    { name: 'height', label: 'Map height', component: 'text' },
    { name: 'api', label: 'Api key', component: 'text' },
  ],
};
