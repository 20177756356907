import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import get from 'lodash.get';
import Img from 'gatsby-image';
import {
  InlineField,
  InlineTextField,
  InlineWysiwyg,
} from 'react-tinacms-inline';
import CarouselSection from 'gatsby-theme-dorian/src/sections/CarouselSection';

export function Carousel({ data, index, ...props }) {
  const [editStatus, setStatus] = useState(false);
  const ContentTitleComponent = (
    <InlineField name={`rawJson.blocks[${index}].title`}>
      {({ input, status }) => {
        setStatus(status === 'active');
        if (status === 'active') {
          return (
            <InlineTextField name={`rawJson.blocks[${index}].title`} />
          );
        }
        return (
          <>
            {input.value}
          </>
        );
      }}

    </InlineField>
  );
  if (data.slides) {
    return (
      <CarouselSection
        key={data.title}
        id={data.title.toLowerCase()}
        title={ContentTitleComponent}
        draggable={!editStatus}
        options={{
          loop: data.loop,
        }}
        {...props}
      >
        {data.slides.map((s, slideIndex) => (
          <>
            {s.image
            && s.image.childImageSharp && (
            <ImageWrapper>
              <Img fluid={s.image.childImageSharp.fluid} />
            </ImageWrapper>
            )}
            {s.content
              && (
              <InlineWysiwyg name={`rawJson.blocks[${index}].slides[${slideIndex}].content`} format="markdown">
                <StyledContent
                  dangerouslySetInnerHTML={{
                    __html: s.contentHtml,
                  }}
                />
              </InlineWysiwyg>
              )}
          </>
        ))}
      </CarouselSection>
    );
  }
  return null;
}

const StyledContent = styled.div`
  ${tw`pr-4`}
  ${(p) => p.center
    && tw`
      text-center
    `};
`;

const ImageWrapper = styled.div`
  ${tw`overflow-hidden pb-4`}
`;

export const CarouselBlock = {
  label: 'Carousel',
  name: 'carousel',
  key: 'test',
  defaultItem: {
    title: '',
  },
  fields: [
    { name: 'title', label: 'Title', component: 'text' },
    {
      label: 'Slides',
      name: 'slides',
      component: 'group-list',
      itemProps: (item) => ({
        key: item.link,
        label: item.label,
      }),
      fields: [
        { name: 'label', label: 'Slide label', component: 'text' },
        { name: 'content', label: 'Content', component: 'markdown' },
        {
          label: 'Image',
          name: 'image',
          component: 'image',
          parse: (filename) => `../images/${filename}`,
          uploadDir: () => '/content/images/',
          previewSrc: (formValues, fieldProps) => {
            const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode');
            const imageNode = get(formValues, pathName);
            if (!imageNode || !imageNode.childImageSharp) return '';
            return imageNode.childImageSharp.fluid.src;
          },
        },
      ],
    },
    { name: 'loop', label: 'Loop', component: 'toggle' },
  ],
};
