import React from 'react';
import { useInlineForm } from 'react-tinacms-inline';
import styled from 'styled-components';
import tw from 'twin.macro';

const EditButton = styled.button`
  ${tw`
    outline-none border-none inline-block leading-none cursor-pointer z-50
    text-base py-2 px-3 rounded fixed uppercase left-0 font-bold
  `}
  top: 140px;
  color: ${(props) => props.theme.color.background};
  background: ${(props) => props.theme.color.primary};
`;

const EditToggle = () => {
  const { status, deactivate, activate } = useInlineForm();

  return (
    <EditButton
      onClick={() => {
        status === 'active' ? deactivate() : activate();
      }}
    >
      {status === 'active' ? 'Preview' : 'Edit'}
    </EditButton>
  );
};

export default EditToggle;
