import React from 'react';
import { graphql } from 'gatsby';
import { InlineForm } from 'react-tinacms-inline';
import { useLocalJsonForm } from 'gatsby-tinacms-json';
import { Form, FormBlock } from '../blocks/Form';
import { Title, TitleBlock } from '../blocks/Title';
import { Content, ContentBlock } from '../blocks/Content';
import { Contacts, ContactsBlock } from '../blocks/Contacts';
import { Carousel, CarouselBlock } from '../blocks/Carousel';
import PageLayout from '../tina/PageLayout';
import { HighlightContentBlock, HighlightContent } from '../blocks/HighlightContent/HighlightContent'

export default function Page({ data }) {
  const [page, form] = useLocalJsonForm(data.page, PageForm);
  const blocks = page.blocks ? page.blocks : [];
  return (
    <InlineForm form={form}>
      <PageLayout page={page}>
        {blocks
          && blocks.map(({ _template, ...blockData }, i) => {
            switch (_template) {
              case 'TitleBlock':
                return <Title key={blockData.title} page={page} index={i} data={blockData} />;
              case 'FormBlock':
                return <Form form={blockData} />;
              case 'CarouselBlock':
                return (
                  <Carousel
                    key={blockData.title}
                    data={blockData}
                    index={i}
                    animationsEnabled={data.theme.animations}
                  />
                );

              case 'ContactsBlock':
                if (blockData.content) {
                  return (
                    <Contacts
                      key={blockData.title}
                      data={blockData}
                      html={blockData.contentHtml}
                      index={i}
                      animationsEnabled={data.theme.animations}
                    />
                  );
                }
                break;
                case 'ContentBlock':
                if (blockData.content) {
                  return (
                    <Content
                      key={blockData.title}
                      data={blockData}
                      html={blockData.contentHtml}
                      index={i}
                      animationsEnabled={data.theme.animations}
                    />
                  );
                }
                break;
                case 'HighlightContentBlock':
                  if (blockData.content) {
                    return (
                      <HighlightContent
                        key={blockData.title}
                        data={blockData}
                        html={blockData.contentHtml}
                        index={i}
                        animationsEnabled={data.theme.animations}
                      />
                    );
                  }
                break;
                case 'RawHTMLBlock':
                if (blockData.content) {
                  return (
                    <div dangerouslySetInnerHTML={{
                      __html: blockData.content,
                    }}>
                    </div>
                  );
                }
                break;
              default:
                return true;
            }
          })}
      </PageLayout>
    </InlineForm>
  );
}

const PageForm = {
  label: 'Page',
  fields: [
    {
      label: 'Title',
      name: 'rawJson.title',
      component: 'text',
    },
    {
      label: 'Hero',
      name: 'rawJson.hero',
      component: 'group',
      fields: [
        {
          label: 'Headline',
          name: 'headline',
          component: 'text',
        },
        {
          label: 'Colored headline',
          name: 'colored',
          component: 'toggle',
        },
        {
          label: 'Textline',
          name: 'textline',
          component: 'text',
        },
        {
          label: 'Actions',
          name: 'ctas',
          component: 'group-list',
          itemProps: (item) => ({
            key: item.key,
            label: item.label,
          }),
          fields: [
            {
              label: 'Label',
              name: 'label',
              component: 'text',
            },
            {
              label: 'Link',
              name: 'link',
              component: 'text',
            },
            {
              label: 'Primary',
              name: 'primary',
              component: 'toggle',
            },
          ],
        },
      ],
    },
    {
      label: 'Page Sections',
      name: 'rawJson.blocks',
      component: 'blocks',
      templates: {
        TitleBlock,
        FormBlock,
        HighlightContentBlock,
        ContentBlock,
        ContactsBlock,
        CarouselBlock,
      },
    },
  ],
};

export const pageQuery = graphql`
  query($path: String!) {
    page: pagesJson(path: { eq: $path }) {
      title
      displayTitle
      hero {
        headline
        textline
        colored
        ctas {
          label
          link
          primary
        }
      }
      blocks {
        _template
        content
        contentHtml
        name
        title
        underline
        center
        recipient
        latitude
        longitude
        marker
        api
        zoom
        height
        loop
        fields {
          label
          inputType
          autocomplete
        }
        slides {
          label
          content
          contentHtml
          image {
            childImageSharp {
              fluid(quality: 97, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

      rawJson
      fileRelativePath
    }
    theme: settingsJson(
        fileRelativePath: { eq: "/content/settings/theme.json" }
      ) {
        animations
    }
  }
`;
