import React from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import { mix } from 'polished';
import slugify from 'react-slugify';
import { Button } from 'gatsby-theme-dorian/src/styles/buttons';

export function Form({ form }) {
  return (
    <StyledForm
      key={form.title}
      name="contact"
      action={`https://formspree.io/${form.recipient}`}
      method="POST"
    >
      {form.fields.map((field) => {
        if (field.inputType === 'textarea') {
          return (
            <FormField wide>
              <label htmlFor={slugify(field.label)}>{field.label}</label>
              <textarea
                cols="40"
                rows="5"
                name={slugify(field.label)}
                id={slugify(field.label)}
              />
            </FormField>
          );
        }
        return (
          <FormField key={field.label}>
            <label htmlFor={slugify(field.label)}>{field.label}</label>
            <input
              id={slugify(field.label)}
              name={slugify(field.label)}
              type={field.inputType}
              autoCorrect="off"
              autoComplete={field.autocomplete || ''}
            />
          </FormField>
        );
      })}
      {form.fields.length > 0 && (
        <FormField wide>
          <Button primary type="submit" value="Submit">
            Submit
          </Button>
        </FormField>
      )}
    </StyledForm>
  );
}

const base = {
  name: 'customInput',
  key: 'label',
  component: 'group',
  fields: [
    { name: 'label', label: 'Label', component: 'text' },
    { name: 'inputType', label: 'Input Type', component: 'text' },
    { name: 'autocomplete', label: 'Autocomplete', component: 'text' },
  ],
};

export const customInputBlock = {
  label: 'Custom Input',
  ...base,
};

export const nameInputBlock = {
  label: 'Name Input',
  defaultItem: {
    label: 'Name',
    inputType: 'text',
    autocomplete: 'name',
  },
  ...base,
};

export const emailInputBlock = {
  label: 'Email Input',
  defaultItem: {
    label: 'Email',
    inputType: 'text',
    autocomplete: 'email',
  },
  ...base,
};

export const phoneInputBlock = {
  label: 'Phone Input',
  defaultItem: {
    label: 'Phone',
    inputType: 'text',
    autocomplete: 'tel',
  },
  ...base,
};

export const companyInputBlock = {
  label: 'Company Input',
  defaultItem: {
    label: 'Company',
    inputType: 'text',
    autocomplete: 'organization',
  },
  ...base,
};

export const messageInputBlock = {
  label: 'Message Input',
  defaultItem: {
    label: 'Message',
    inputType: 'textarea',
    autocomplete: '',
  },
  ...base,
};

export const FormBlock = {
  label: 'Form',
  key: 'name',
  name: 'form',
  component: 'group',
  defaultItem: {
    name: 'Form',
    recipient: '',
    fields: [],
  },
  fields: [
    { name: 'name', label: 'Name', component: 'text' },
    {
      name: 'recipient',
      label: 'Recipient',
      description: 'Form is sent via formspree.io.',
      component: 'text',
    },
    {
      label: 'Fields',
      name: 'fields',
      component: 'blocks',
      templates: {
        customInputBlock,
        nameInputBlock,
        emailInputBlock,
        phoneInputBlock,
        companyInputBlock,
        messageInputBlock,
      },
    },
  ],
};

export const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
  justify-items: stretch;
  width: 50%;
  margin: auto;
`;

export const FormField = styled.div`
  input,
  textarea {
    ${tw`relative leading-9 text-base pb-3 border-none w-full`}
    transition: box-shadow 150ms ${(p) => p.theme.easing};
    color: ${(p) => p.theme.color.foreground};
    background-color: ${(p) => mix(0.75, p.theme.color.background, p.theme.color.foreground)};

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${(p) => p.theme.color.secondary};
    }
  }

  textarea {
    ${tw`leading-normal py-2 px-3 resize-y`}
  }

  label {
    ${tw`block mb-1`}
  }

  ${(p) => p.wide
    && css`
  
    `};
`;
