import React from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import { InlineTextField } from 'react-tinacms-inline';

const StyledTitle = styled.h2`
  @media (max-width:640px){
    margin-top: 5rem;
  }  
  margin-top: -7rem;
  ${tw`
    text-4xl leading-tight font-bold
  `}

  ${(p) => p.center
    && css`
      text-align: center;
    `};
`;

const Hr = styled.hr`
  ${tw`my-10`}
  ${(p) => p.center
    && css`
      ${tw`mx-auto`}
    `};
`;

export function Title({ index, data }) {
  const centered = data.center ? data.center : false;
  return (
    <>
      <StyledTitle center={centered}>
        <InlineTextField name={`rawJson.blocks[${index}].title`} />
      </StyledTitle>
      {data && data.underline && <Hr center={centered} />}
    </>
  );
}

export const TitleBlock = {
  label: 'Title',
  name: 'title',
  defaultItem: {
    title: '',
    center: false,
    underline: true,
  },
  fields: [
    { name: 'title', label: 'Title', component: 'text' },
    { name: 'center', label: 'Center', component: 'toggle' },
    { name: 'underline', label: 'Underline', component: 'toggle' },
  ],
};
