const removeNull = (obj) => Object.keys(obj)
  .filter((k) => obj[k] != null) // Remove undef. and null.
  .reduce(
    (newObj, k) => (typeof obj[k] === 'object'
      ? { ...newObj, [k]: removeNull(obj[k]) } // Recurse.
      : { ...newObj, [k]: obj[k] }), // Copy value.
    {},
  );

export default removeNull;
