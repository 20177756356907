import React from 'react';
import styled from 'styled-components';
import get from 'lodash.get';
import cloudIcon from './icons/cloud.svg';
import checkListIcon from './icons/checklistt.svg';
import gearsIcon from './icons/gears-solid.svg';

export function HighlightContent() {
  const content = [
    {
      icon: gearsIcon,
      title: 'Infrastructure Design and Implementation',
      description: 'From proof of concept to production deployment in adequate time.',
    },
    {
      icon: checkListIcon,
      title: 'Cloud Infrastructure Audits',
      description:
        'One-time analysis for cloud best practices, security and cost optimization.',
    },
    {
      icon: cloudIcon,
      title: 'Migrating Workloads to Cloud',
      description:
        'Want to go full cloud from on-prem or hybrid deployments? We can help with that.',
    },
  ];
  return (
    <>
      <StyledWrapper>
        {content.map((item, index) => (
          <div style={{ flex: 1 }}>
            <div
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src={item.icon}
                alt="icon-example"
                style={{
                  width: '40%',
                  marginTop: '50px',
                  marginBottom: '20px',
                }}
              />
              <h3
                style={{
                  textAlign: 'center',
                  color: 'inherit',
                  marginBottom: index !== 0 ? '45px' : '14px',

                }}
              >
                {item.title}
              </h3>
              <p style={{ textAlign: 'center', color: 'inherit' }}>
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </StyledWrapper>
    </>
  );
}

const StyledWrapper = styled.div`
@media (max-width:640px){
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  background-color: inherit;
  height: auto;
  margin-bottom: 100px;
}
  border-radius: 60px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  background-color: inherit;
  height: 400px;
  margin-bottom: 100px;
`;

export const HighlightContentBlock = {
  label: 'HighlightContent',
  name: 'HighlightContent',
  key: 'test',
  defaultItem: {
    title: '',
    content: 'Default text',
    center: false,
    image: null,
  },
  fields: [
    { name: 'title', label: 'Title', component: 'text' },
    { name: 'content', label: 'Content', component: 'markdown' },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      parse: (filename) => `../images/${filename}`,
      uploadDir: () => '/content/images/',
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode');
        const imageNode = get(formValues, pathName);
        if (!imageNode || !imageNode.childImageSharp) return '';
        return imageNode.childImageSharp.fluid.src;
      },
    },
    { name: 'center', label: 'Center', component: 'toggle' },
  ],
};
