import React from 'react';
import { InlineField, InlineTextField } from 'react-tinacms-inline';
import { Wrapper } from 'gatsby-theme-dorian/src/styles/mainStyles';
import { LinkButton } from 'gatsby-theme-dorian/src/styles/buttons';
import {
  HeroWrapper,
  ColoredText,
  HeroContent,
  HeroText,
  Headline,
  Textline,
  Actions,
} from 'gatsby-theme-dorian/src/styles/heroStyles';
import ContentSection from 'gatsby-theme-dorian/src/sections/ContentSection';


const Hero = ({ hero, animationsEnabled }) => (
  <HeroWrapper>
    {(hero.headline || hero.textline || hero.ctas) && (
      <HeroContent>
        <Wrapper>
          <ContentSection title={null} animationsEnabled={animationsEnabled}>
            <HeroText>
              {hero.headline && (
                <InlineField name="rawJson.hero.headline">
                  {({ input, status }) => {
                    const headlineText = input.value.split(' ');
                    const lastWord = headlineText.pop();
                    if (status === 'active') {
                      return (
                        <Headline>
                          <InlineTextField name="rawJson.hero.headline" />
                        </Headline>
                      );
                    }
                    return (
                      <Headline>
                        {headlineText.join(' ')}
                        <ColoredText colored={hero.colored}>
                          {' '}
                          {lastWord}
                        </ColoredText>
                      </Headline>
                    );
                  }}
                </InlineField>
              )}
              {hero.textline && (
                <Textline>
                  <InlineTextField name="rawJson.hero.textline" />
                </Textline>
              )}
              {hero.ctas && (
                <Actions>
                  {Object.keys(hero.ctas).map((key) => (
                    <LinkButton
                      key={key}
                      primaryButton={hero.ctas[key].primary}
                      to={hero.ctas[key].link}
                    >
                      {hero.ctas[key].label}
                    </LinkButton>
                  ))}
                </Actions>
              )}
            </HeroText>
          </ContentSection>
        </Wrapper>
      </HeroContent>
    )}
  </HeroWrapper>
);

export default Hero;
