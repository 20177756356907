import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import get from 'lodash.get';
import Img from 'gatsby-image';
import {
  InlineTextField,
  InlineWysiwyg,
} from 'react-tinacms-inline';
import ContentSection from 'gatsby-theme-dorian/src/sections/ContentSection';

export function Content({
  data, html, index, ...props
}) {
  const centered = data.center ? data.center : false;
  const ContentTitleComponent = (<InlineTextField name={`rawJson.blocks[${index}].title`} />);
  return (
    <ContentSection
      id={data.title ? data.title.toLowerCase() : ''}
      title={ContentTitleComponent}
      {...props}
    >
      {data.image
        && data.image.childImageSharp && (
          <ImageWrapper>
            <Img fluid={data.image.childImageSharp.fluid} />
          </ImageWrapper>
      )}
      <InlineWysiwyg name={`rawJson.blocks[${index}].content`} format="markdown">
        <StyledContent
          center={centered}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </InlineWysiwyg>
    </ContentSection>
  );
}

const StyledContent = styled.div`
  ${(p) => p.center
    && tw`
      text-center
    `};
`;

const ImageWrapper = styled.div`
  ${tw`overflow-hidden pb-4`}
`;

export const ContentBlock = {
  label: 'Content',
  name: 'content',
  key: 'test',
  defaultItem: {
    title: '',
    content: 'Default text',
    center: false,
    image: null,
  },
  fields: [
    { name: 'title', label: 'Title', component: 'text' },
    { name: 'content', label: 'Content', component: 'markdown' },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      parse: (filename) => `../images/${filename}`,
      uploadDir: () => '/content/images/',
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode');
        const imageNode = get(formValues, pathName);
        if (!imageNode || !imageNode.childImageSharp) return '';
        return imageNode.childImageSharp.fluid.src;
      },
    },
    { name: 'center', label: 'Center', component: 'toggle' },
  ],
};
