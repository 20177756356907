import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useGlobalJsonForm } from 'gatsby-tinacms-json';
import merge from 'lodash.merge';
import { Wrapper, Main } from 'gatsby-theme-dorian/src/styles/mainStyles';
import SEO from 'gatsby-theme-dorian/src/components/Seo';
import { ThemeContext, ThemeForm } from './Theme';
import Hero from './Hero';
import removeNull from '../utils/removeNull';
import { NavForm } from './Navigation';
import { FooterNavForm } from './Footer';
import EditToggle from './EditToggle';


const PageLayout = ({ page, children }) => {
  const data = useStaticQuery(graphql`
    query PageLayoutQuery {
      nav: settingsJson(
        fileRelativePath: { eq: "/content/settings/menu.json" }
      ) {
        ...nav
        rawJson
        fileRelativePath
      }
      footerNav: settingsJson(
        fileRelativePath: { eq: "/content/settings/footerMenu.json" }
      ) {
        ...footerNav
        rawJson
        fileRelativePath
      }
      theme: settingsJson(
        fileRelativePath: { eq: "/content/settings/theme.json" }
      ) {
        ...globalTheme

        rawJson
        fileRelativePath
      }
      site: settingsJson(
        fileRelativePath: { eq: "/content/settings/site.json" }
      ) {
        logo
        title
        description
        author
        rawJson
        fileRelativePath
      }
    }
  `);
  useGlobalJsonForm(data.nav, NavForm);
  useGlobalJsonForm(data.footerNav, FooterNavForm);
  useGlobalJsonForm(data.theme, ThemeForm);
  useGlobalJsonForm(data.site, SiteForm);

  const themeContext = React.useContext(ThemeContext);
  const { theme } = themeContext;
  const siteTitle = data.site.title;
  const { description, author } = data.site;
  const pageTitle = page && page.title;
  const hero = page.hero
    ? merge({}, theme.hero, removeNull(page.hero))
    : theme.hero;

  return (
    <>
      {pageTitle
      && (
        <SEO
          title={pageTitle}
          siteTitle={siteTitle}
          description={description}
          author={author}
        />
      )}
      <Hero hero={hero} animationsEnabled={data.theme.animations} />
      <Main>
        <Wrapper>{children}</Wrapper>
      </Main>
      {process.env.NODE_ENV !== 'production' && <EditToggle />}
    </>
  );
};

export default PageLayout;

const SiteForm = {
  label: 'Site',
  fields: [
    {
      label: 'Logo',
      name: 'rawJson.logo',
      component: 'text',
      parse(value) {
        return value || '';
      },
    },
    {
      label: 'Title',
      name: 'rawJson.title',
      component: 'text',
      parse(value) {
        return value || '';
      },
    },
    {
      label: 'Description',
      name: 'rawJson.description',
      component: 'text',
      parse(value) {
        return value || '';
      },
    },
    {
      label: 'Author',
      name: 'rawJson.author',
      component: 'text',
      parse(value) {
        return value || '';
      },
    },
  ],
};
